import Vue from 'vue'
import { Store } from 'vuex'
import { Route } from 'vue-router'

declare module 'vue/types/vue' {
    // Global properties can be declared
    // on the `VueConstructor` interface
    interface VueConstructor {
        $axios: any
        router: any
        $route: Route
        playerBus: any
        $store: Store<any>
    }

    interface Vue {
        $axios: any
        router: any
        $route: Route
        playerBus: any
        $store: Store<any>
    }
}

import App from './App.vue'
import router from '@embed/router'
import store from '@embed/store'
import '@embed/plugins'
import '~/directives'

import '@embed/sass/main.sass'
import { updateCssVariable } from '~/utils/updateCssVariable'

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: (h: any) => h(App)
}).$mount('#app')

// listen to changes from producer dashboard
function receiveMessage(event: any) {
    // Do we trust the sender of this message?  (might be
    // different from what we originally opened, for example).
    if (!['https://localhost:8080', 'https://producer.thecharts.com'].includes(event.origin)) {
        console.error('source not trusty')
        return
    }
    // a new amplify player configuration was sent
    // from the producer dashboard
    const amplifyPlayerConfiguration: IAmplifyPlayer = event.data
    // update css variables from js variables
    Object.keys(amplifyPlayerConfiguration).forEach(it => {
        const key = it as keyof IAmplifyPlayer
        updateCssVariable(key, amplifyPlayerConfiguration[key])
    })
}

window.addEventListener('message', receiveMessage, false)
