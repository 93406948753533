/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'play-o': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.235 10.224L6.111 1.282C4.882.556 3 1.261 3 3.057v17.878c0 1.612 1.749 2.583 3.11 1.775l15.125-8.937c1.35-.795 1.354-2.754 0-3.55zm-.696 2.367L5.415 21.528c-.456.262-1.04-.051-1.04-.593V3.057c0-.7.705-.79 1.035-.593l15.125 8.941a.69.69 0 01.004 1.186z" _fill="#FFF"/>'
  }
})
