import { namespace } from 'vuex-class'

export const UserStore = namespace('user')

interface State {
    boughtBeats: IBoughtBeat[]
}

export default {
    namespaced: true,
    state: {
        boughtBeats: [],
    } as State
}
