import { castParam, LocaleComponent } from '~/services/router-helper'

export default [
    {
        path: '/:id',
        components: { default: LocaleComponent },
        children: [
            {
                path: '/',
                name: 'Home',
                components: { default: () => import('@embed/views/Home.vue') },
                props: { default: true }
            },
            {
                path: 'cart',
                name: 'Cart',
                components: {
                    default: () => import(/* webpackChunkName: "Cart" */ '@embed/views/Cart.vue'),
                },
                props: { default: true }
            },
            {
                path: 'cart/finish-paypal/:orderId',
                name: 'FinishPaypal',
                components: { default: () => import(/* webpackChunkName: "Cart" */ '@/views/FinishPaypal.vue') },
                props: { default: castParam }
            },
            {
                path: 'order/:email/:orderId',
                name: 'OrderReview',
                components: { default: () => import(/* webpackChunkName: "OrderReview" */ '~/views/OrderReview.vue') },
                props: { default: castParam }
            },
        ]
    }
]
