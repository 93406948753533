





import ModalManager from '@elitepage/components/modals/Index.vue'
import { Vue, Component } from 'vue-property-decorator'

@Component({ components: { ModalManager } })
export default class App extends Vue {}
