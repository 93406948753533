/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cart-o': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M5.035 4.933h17.598c.147 0 .22.074.294.147.073.073.073.22.073.293l-1.833 7.04a.573.573 0 01-.294.294l-13.922 1.75c.37.853 1.201 1.476 2.189 1.476H19.7c.22 0 .367.147.367.367s-.147.367-.367.367H9.14c-1.467 0-2.787-1.1-3.08-2.567L3.787 2.733h-2.42c-.22 0-.367-.146-.367-.366S1.147 2 1.367 2h2.786c.147 0 .294.147.367.293l.515 2.64zm.143.734l1.577 8.087a.617.617 0 01.038-.02l13.64-1.76 1.687-6.307H5.178zM9.067 21.8c-1.247 0-2.2-.953-2.2-2.2 0-1.247.953-2.2 2.2-2.2 1.246 0 2.2.953 2.2 2.2 0 1.247-.954 2.2-2.2 2.2zm0-3.667c-.807 0-1.467.66-1.467 1.467s.66 1.467 1.467 1.467c.806 0 1.466-.66 1.466-1.467s-.66-1.467-1.466-1.467zM18.6 21.8c-1.247 0-2.2-.953-2.2-2.2 0-1.247.953-2.2 2.2-2.2 1.247 0 2.2.953 2.2 2.2 0 1.247-.953 2.2-2.2 2.2zm0-3.667c-.807 0-1.467.66-1.467 1.467s.66 1.467 1.467 1.467 1.467-.66 1.467-1.467-.66-1.467-1.467-1.467z" id="svgicon_cart-o_a"/></defs><use xlink:href="#svgicon_cart-o_a"/>'
  }
})
