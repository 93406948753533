import Vue from 'vue'
import Router from 'vue-router'

import routes from '@embed/router/routes'
import { scrollBehavior } from '~/utils/scrollBehaviour'

Vue.use(Router)

const router = new Router({ mode: 'history', routes, scrollBehavior })

Vue.router = router
Object.defineProperty(Vue.prototype, 'router', { get: () => router })

export default router
