import Vue from 'vue'
import Vuex from 'vuex'

import beat from '~/store/beat'
import cart from '~/store/cart'
import modal from '~/store/modal'
import player from '~/store/player'
import user from '@embed/store/user'
import { vuexToken } from '~/store/vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [vuexToken],
    modules: { beat, cart, modal, player, user }
})
