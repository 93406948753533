/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pause-o': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.143 22h4.286c1.183 0 2.142-.96 2.142-2.143V4.143C10.571 2.96 9.611 2 8.43 2H4.143C2.96 2 2 2.96 2 4.143v15.714C2 21.04 2.96 22 4.143 22zM3.429 4.143c0-.393.321-.714.714-.714h4.286c.392 0 .714.321.714.714v15.714a.716.716 0 01-.714.714H4.143a.716.716 0 01-.714-.714V4.143zM15.57 22h4.286C21.04 22 22 21.04 22 19.857V4.143C22 2.96 21.04 2 19.857 2h-4.286c-1.183 0-2.142.96-2.142 2.143v15.714c0 1.183.96 2.143 2.142 2.143zm-.714-17.857c0-.393.322-.714.714-.714h4.286c.393 0 .714.321.714.714v15.714a.716.716 0 01-.714.714H15.57a.716.716 0 01-.714-.714V4.143z" _fill="#FFF"/>'
  }
})
